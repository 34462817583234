<template>
  <div class="sp-notification">
    <account-page-title title="Notifications" class="sp-notification-title" />

    <p class="sp-notification-subtitle">
      Manage your subscription. We`ll never spam and you can always unsubscribe
      at any time.
    </p>

    <hr />

    <checkbox-component :value="notification" @input="handleToggleNotification">
      <span class="ml-2 sp-checkbox-text">Email subscription</span>
    </checkbox-component>
  </div>
</template>

<script>
  import SharedAmsDataService from 'src/shared/services/ams/ams-data.service';

  import AccountPageTitle from '../../components/account-page-title.component';
  import CheckboxComponent from 'src/shared/features/checkout/components/checkbox.component';

  const AmsService = new SharedAmsDataService();

  export default {
    name: 'NotificationContainer',

    components: {
      AccountPageTitle,
      CheckboxComponent,
    },

    data() {
      return {
        loading: false,

        user: null,

        notification: false,
      };
    },

    async mounted() {
      await this.fetchUserData();

      this.$bus.$emit('updateTitle', 'Notification');
    },

    methods: {
      async fetchUserData() {
        try {
          const response = await AmsService.getAccount();
          console.log(response);
          this.notification = response.info.user_info.notification_consent;
        } catch (error) {
          console.log(error);
        }
      },

      async handleToggleNotification(value) {
        this.loading = true;

        try {
          this.notification = value;

          await AmsService.updateAccount({
            notification_consent: this.notification,
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-notification {
    @media screen and (min-width: 1001px) {
      padding-top: 55px;
    }

    &-title {
      margin-bottom: 6px;
    }

    &-subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #b6c0d3;
      margin-bottom: 23px;
    }

    .sp-checkbox-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      color: #343434;
    }
  }
</style>
