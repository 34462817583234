<template>
  <notification-container />
</template>

<script>
  import NotificationContainer from './notification.container';
  export default {
    name: 'NotificationView',
    components: {
      NotificationContainer,
    },
  };
</script>

<style></style>
